import React from 'react'

import { ThemeProvider } from '@dasa-health/alma-react'
import { MainProvider } from '../components/Hooks/mainStates'

import Layout from '../components/layout'

const IndexPage = () => {
  return(
    <ThemeProvider>
      <MainProvider>
        <Layout />
      </MainProvider>
    </ThemeProvider>
  )
}

export default IndexPage
